<template>
  <div>
    <v-app-bar style="z-index:300" class="app-nav" height="98" app fixed color="black">
      <v-container>
        <v-row>
          <v-col class="pa-0"></v-col>
          <v-col class="d-flex justify-center align-center pa-0">
            <img
              @click="goHome"
              src="@/assets/img/dgLogo.svg"
              alt="logo"
              class="dg-logo pointer"
            />
          </v-col>
          <v-col class="pa-0">
            <div class="d-md-none d-flex justify-end">
              <!-- <v-btn rounded class="nav-btn mr-3 pa-5">CONTACT</v-btn> -->
              <!-- <v-btn rounded class="nav-btn mr-3 pa-5">TC</v-btn> -->
              <v-app-bar-nav-icon
                @click.stop="drawer = !drawer"
              ></v-app-bar-nav-icon>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <!-- 側邊選單 -->
      <div class="app-nav__side-menu d-md-block d-none">
        <div @click="goHome" class="app-nav__side-menu__item">Home</div>
        <div @click="goProjects" class="app-nav__side-menu__item">Projects</div>
        <div @click="goAboutUs" class="app-nav__side-menu__item">About us</div>
      </div>
    </v-app-bar>

    <v-navigation-drawer class=" d-block main-drawer" v-model="drawer" app disable-resize-watcher>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
        >
          <v-list-item @click="goHome">
            <v-list-item-title class="app-nav__drawer" >Home</v-list-item-title>
          </v-list-item>

          <v-list-item @click="goProjects">
            <v-list-item-title class="app-nav__drawer" >Projects</v-list-item-title>
          </v-list-item>

          <v-list-item @click="goAboutUs">
            <v-list-item-title class="app-nav__drawer" >About us</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  computed: {},
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    },

    goProjects() {
      this.$router.push({ name: "projects" });
    },

    goAboutUs() {
      this.$router.push({ name: "aboutUs" });
    },
  },
  data: () => ({
    drawer: false,
    group: null,
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
