module.exports = {
  siteName: "dgfactor-official",
  language: "zh-TW",
  seoTitle: '數位因子',
  seoDescription: `本公司於2001年成立，早期主要提供網站建置、網站代管、AWS/GCP雲端運算規劃建置與維護等服務，至今已服務超過500多家客戶，服務的客戶包括上市上櫃公司、政府機關與知名企業，也遍及美澳及中國大陸，我們的技術已經可以開發大型叢集高流量網站，並可以建置結合影音串流互動社群網站。
  目前公司逐步轉型開發自有產品，目前有宗教IoT產品，還有電商平台SaaS服務。
  此外我們因為有強大的技術能力，我們提供許多新創公司技術能量，讓他們可以在艱困的創業開頭，只要專心他們的商業模式，技術就交給我們。`,
  seoKeywords: '',
  seoPhoto: '',
};
