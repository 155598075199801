<template>
  <div class="dot-text" :class="dotTextClass">
    <div
      :style="`background: ${dotColor};`"
      class="dot-text__dot"
      :class="dotClass"
    ></div>
    <p :style="`color: ${textColor};`" class="mb-0">{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
    textColor: {
      type: String,
      default: "#B8B8B9",
    },
    dotColor: {
      type: String,
      default: "#00B1FF",
    },
    isDotAbsolute: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    dotTextClass() {
      if (!this.isDotAbsolute) {
        return `d-flex align-center`;
      }
      return "";
    },
    dotClass() {
      if (this.isDotAbsolute) {
        return `dot-text__dot--absolute`;
      }
      return "";
    },
  },
};
</script>

<style lang="sass">
@import "src/assets/sass/util/_variable.sass"
.dot-text
  position: relative
.dot-text__dot
  width: 5px
  height: 5px
  border-radius: 50%
  margin-right: 1rem
  &--absolute
    position: absolute
    top: 50%
    left: -1rem
    transform: translateY(-50%)
    margin-right: 0
</style>