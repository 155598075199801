import Vue from 'vue'

class nl2br {
  constructor() {
    window.nl2br = (data) => this.nl2br(data)
    Vue.prototype.nl2br = (data) => this.nl2br(data)
    Vue.filter('nl2br', (data) => this.nl2br(data))
  }

  nl2br(data) {
    const result = String(data).replace(/(?:\r\n|\r|\n)/g, '<br>')
    if(!result) return ''
    if(result == 'null') return ''
    return result
  }
}

export default new nl2br()