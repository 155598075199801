import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home.vue'),
  },
  {
    path: '/detail/ecv',
    name: 'detail.ecv',
    component: () => import('@/views/detail/ecv.vue'),
  },
  {
    path: '/detail/hakka',
    name: 'detail.hakka',
    component: () => import('@/views/detail/hakka.vue'),
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/aboutUs.vue'),
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/projects.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
