import Vue from "vue";
import i18n from "libs/i18n";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import apiLibrary from "libs/api/api.js";
import helper from "libs/helper";
import seo from "libs/seo.js";
import "libs/ssr.js";
import "libs/nl2br";
Vue.prototype.$seo = seo;
import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

import $ from "jquery";
import { textRequestByApi } from "config/env.json";
Vue.config.productionTip = false;

import DotText from "@/components/detail/dotText.vue";
Vue.component("DotText", DotText);

window.$ = $;

class Application {
  async launch() {
    window.rootComponent = new Vue({
      i18n,
      metaInfo() {
        return {
          title: this.vueMeta.title,
          link: this.vueMeta.link,
          meta: this.vueMeta.meta,
          script: this.vueMeta.script,
        };
      },
      data: () => ({
        vueMeta: {
          title: "",
          link: [],
          meta: [],
          script: [],
        },
      }),
      created() {
        this.initKernel();
        this.$vuetify.theme.dark = true;
      },
      methods: {
        async initKernel() {
          window.helper = helper;
          Vue.prototype.$helper = helper;
          // 註冊api library到vue prototype讓每個vue元件可使用api
          Vue.prototype.$api = await apiLibrary();

          await this.initApplication();
          this.setupI18n();
          window.rootComponent.$mount("#app");
        },
        async initApplication() {
          await this.initApplicationRequest();
        },
        async initApplicationRequest() {
          this.$store.dispatch(`base/set`, {
            key: "applicationReady",
            value: false,
          });
          await this.$nextTick();
          await this.$helper.delay(0.2);
          try {
            await this.$nextTick();
            this.setupMeta();
          } catch (error) {
            console.error(error);
          }
          this.$store.dispatch(`base/set`, {
            key: "applicationReady",
            value: true,
          });
        },
        setupMeta() {
          if (!this.siteData) return;
          this.vueMeta.title = this.pageTitle;
          this.vueMeta.meta = this.$seo.getMetaArray({
            title: this.pageTitle,
            description: this.seoDescription,
            keywords: this.seoKeywords,
            photo: this.seoPhoto,
          });
        },
        setupI18n() {
          this.$i18n.locale = this.lang;
          this.$vuetify.lang.current = this.lang;
          // 到時候拿來加入api取得的i18n翻譯檔
          // this.$i18n.mergeLocaleMessage(this.$i18n.locale, this.frontendText);

          // @ts-ignore
          Vue.filter("t", (...args) => this.$t(...args));
          // @ts-ignore
          Vue.filter("tc", (...args) => this.$tc(...args));
          // @ts-ignore
          Vue.filter("te", (...args) => this.$te(...args));
        },
      },
      router,
      store,
      vuetify,
      render: (h) => h(App),
      computed: {
        siteData() {
          return this.$store.getters["base/siteData"];
        },
        lang() {
          return this.$store.getters["base/siteLanguage"];
        },
        siteName() {
          return this.$store.getters["base/siteName"];
        },
        seoTitle() {
          return this.$store.getters["base/seoTitle"];
        },
        pageTitle() {
          if (!this.seoTitle) return this.siteName;
          return `${this.seoTitle} - ${this.siteName}`;
        },
        seoDescription() {
          return this.$store.getters["base/seoDescription"];
        },
        seoKeywords() {
          return this.$store.getters["base/seoKeywords"];
        },
        seoPhoto() {
          return this.$store.getters["base/seoPhoto"];
        },
      },
    });
  }
}

new Application().launch();
