class seo {
  init(vm, data = { siteName, domain, logoUrl }) {
    this.vm = vm
    this.siteName = data.siteName
    this.domain = data.domain
    this.logoUrl = data.logoUrl
  }

  getMetaArray(data = { keywords, title, description, photo }) {
    let meta = []

    if(data.title) {
      meta = meta.concat([
        {
          vmid: 'og:title',
          property: 'og:title',
          content: data.title,
        },
      ])
    }

    if(data.keywords) {
      meta = meta.concat([
        {
          vmid: 'keywords',
          name: 'keywords',
          content: data.keywords,
        },
      ])
    }

    if(data.description) {
      meta = meta.concat([
        {
          vmid: 'description',
          name: 'description',
          content: data.description,
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: data.description,
        },
      ])
    }

    if(data.photo) {
      meta = meta.concat([
        {
          vmid: 'og:image',
          property: 'og:image',
          content: window.helper.getPhotoUrl(data.photo,   ['middle', 'small', 'tiny']),
        },
      ])
    }

    return meta
  }

  getBaseGoogleStructuredData() {
    return [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'Organization',
          url: this.domain,
          name: this.siteName,
        }
      }
    ]
  }

  getPostGoogleStructuredData(data = { title, description, photo, createdAt, updatedAt }) {
    let blogPosting = {
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      'mainEntityOfPage': {
        '@type': 'WebPage',
        '@id': this.domain,
      },
      'headline': data.title,
      'datePublished': this.vm.$helper.moment(data.createdAt).format('YYYY-MM-DDTHH:mm:ssZ'),
      'dateModified': this.vm.$helper.moment(data.updatedAt).format('YYYY-MM-DDTHH:mm:ssZ'),
      'image': this.vm.$helper.getPhotoUrlArray(data.photo) || this.logoUrl,
      'author': {
        '@type': 'Organization',
        'name': this.siteName,
      },
      'publisher': {
        '@type': 'Organization',
        'name': this.siteName,
        'logo': {
          '@type': 'ImageObject',
          'url': this.logoUrl,
        }
      },
    }

    if(data.description) {
      blogPosting.description = data.description
    }

    return [
      {
        type: 'application/ld+json',
        json: blogPosting,
      },
    ]
  }
}

export default new seo()
