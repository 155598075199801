import Vue from "vue";
import VueI18n from "vue-i18n";
import textjson from "@/config/text.json"
Vue.use(VueI18n);


export default new VueI18n({
  locale: "zh-TW",
  fallbackLocale: "zh-TW",
  messages: textjson,
  modifiers: {},
});
