import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 576,
      sm: 769,
      md: 901,
      lg: 1201,
    },
    scrollBarWidth: 0,
  },
  theme: {
    themes: {
      dark: {
        primary: "#B8B8B9",
        secondary: "#004B7D",
        accent: "#8c9eff",
        error: "#b71c1c",
        gray: "#F6F6F6",
        green: "#1DD57F",
        darkGreen: "#209943",
        blue: "#00B1FF",
        darkblue: "#1867D0",
        darkGrey: "#2B2B2B",
      },
    },
  },
  icons: {
    // https://remixicon.com/?ref=techmoon
    values: {
      search: "ri-search-line",
      close: "ri-close-line",
      arrowLeft: "ri-arrow-left-s-line",
      arrowRight: "ri-arrow-right-s-line",
      global: "ri-global-line",
      burger: "ri-menu-line",
      search: "ri-search-line",
      add: "ri-add-line",
    },
  },
});
